// Goals.jsx
import React, { Component } from 'react';

class Goals extends Component{

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    saveAndExit = (e) => {
        e.preventDefault();
        $.ajax({
            data: this.props.values,
            type: "POST",
            url: "/custom_session/submit/"
        });
    }

    render(){
        const { values } = this.props
        return(
            <div>
            	<h2 id='sign-up-header'>Your goals</h2>
                <p className='horizontal-divider-below light-gray-divider'>How can our speech & language clinic help?</p>

                <div className='spacer-32'/>

                <p className='m-0'><b>1. What are you most concerned about?</b> (optional)</p>
                <div className='spacer-8'/>
                <textarea
                    onChange={this.props.handleChange('concerns')}
                    defaultValue={values.concerns}
                />

                <div className='spacer-48'/>

                <p className='m-0'><b>2. What does success look like for you?</b> (optional)</p>
                <div className='spacer-8'/>
                <textarea
                    onChange={this.props.handleChange('successMetrics')}
                    defaultValue={values.successMetrics}
                />

                <div className='spacer-32'/>

                <div className='row'>
                    <div className='col-4 m-0 pr-2'>
                        <button className='back-button sign-up-white-btn p-0 m-0' onClick={this.back}>BACK</button>
                    </div>
                    <div className='col-8 m-0 pl-2'>
                        <button className="continue-button square-blue-button p-0 m-0" onClick={this.saveAndExit}>CONTINUE</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Goals;