import React, { Component } from 'react';

export default class Autocomplete extends Component {
	constructor (props) {
		super(props);
		this.items = [
			'male', 'female'
		];
		this.state = {
			suggestions: [],
			text: '',
		};
	}

	onTextChanged = (e) => {
		const value = e.target.value;
		let suggestions = [];
		if (value.length > 0) {
			const regex = new RegExp(`^${value}`, 'i');
			suggestions = this.items.sort().filter(v => regex.test(v));
		}
		this.setState(() => ({ suggestions, text: value }));
	}

	suggestionSelected (value) {
		this.setState(() => ({
			text: value,
			suggestions: [],
		}));
	}

	renderSuggestions () {
		const { suggestions } = this.state;
		if (suggestions.length === 0){
			return null;
		}
		else {
			return (
				<ul>
					{suggestions.map((item) => <li onClick={ () => this.suggestionSelected(item) }>{item}</li>)}
				</ul>
			);
		}
	}

	render () {
		const { text } = this.state;
		const { values } = this.props;
        var event = new Event('input', { bubbles: true });

		return (
			<div className='autocomplete ml-16px'>
				<input value={text} onChange={ this.onTextChanged } type='text' id='patient-gender' />  
				{ this.renderSuggestions() }
			</div>
		);
	}
}