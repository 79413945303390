$('document').ready(function() {
    $('.video-player').append(`
        <div class="controls">
            <div class="video-progress-bar">
                <div class="video-progress"></div>
                <div class="video-scrub"></div>
            </div>
            <div class="buttons">
                <button class="play-pause play material-icons-round"></button>
            </div>
        </div>
    `);

    var videos = []
    $('.video-player').each(function() {
        videos.push([$(this).find('video').get(0), $(this).find('.video-progress')]);
    });

    videos.forEach(function(video) {
        video[0].addEventListener('timeupdate', function () {
            var progressPos = video[0].currentTime / video[0].duration;
            video[1].css('width', (progressPos*100)+'%');
        });
        video[0].onended = function() {
            video[1].parentsUntil('.video-player').parent().find('.play-pause').removeClass('pause').addClass('play');
        }
    });

    $('.video-player .play-pause').click(function() {
        var video = $(this).parentsUntil('.video-player').parent().find('video').get(0);
        if( video.paused ) video.play();
        else video.pause();
        $(this).toggleClass(['play', 'pause']);
    });

    $('.video-player .video-progress-bar').click(function(e) {
        var video = $(this).parentsUntil('.video-player').parent().find('video').get(0);
        video.currentTime = ((e.pageX - $(this).parent().offset().left) / ($(this).width())) * video.duration;
    });

    $('.video-player .video-progress-bar').mousemove(function(e) {
        $(this).find('.video-scrub').css('width', (e.pageX - $(this).parent().offset().left));
    })  

    $('.video-player .video-progress-bar').mouseout(function() {
         $(this).find('.video-scrub').css('width', '0');
    }) 

});