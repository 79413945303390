// MainForm.jsx
import React from "react"
import PropTypes from "prop-types"

import StepCounter from './StepCounter';
import DataPrivacyAndSecurity from './DataPrivacyAndSecurity';
import PatientInformation from './PatientInformation';
import Goals from './Goals';

class MainForm extends React.Component {
    state = {
        step: 1,
        user_type: this.props.user_type,

        aggreementPp: false,
        aggreementTos: false,

        patientFirstName: '',
        patientLastName: '',
        patientGender: '',
        patientBirthday: '',

        concerns: '',
        successMetrics: ''
    }

    nextStep = () => {
        const { step } = this.state
        this.setState({
            step : step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1
        })
    }

    handleChange = input => event => {
        console.log(event.target);
        if( event.target.type == 'checkbox' ){
            this.setState({ [input] : event.target.checked });
        }
        else{
            this.setState({ [input] : event.target.value });
        }
    }

    render(){
        const { step } = this.state;
        const { user_type, aggreementPp, aggreementTos, patientFirstName, patientLastName, patientGender, patientBirthday, concerns, successMetrics } = this.state;
        const values = { user_type, aggreementPp, aggreementTos, patientFirstName, patientLastName, patientGender, patientBirthday, concerns, successMetrics };
        switch(step) {
        case 1:
            return(
                <div>
                    <div className="row justify-content-center">
                        <div className="center-column col-12">
                            <div className="standard-shadow-card card my-4">
                                <div className="card-body onboard-card-padding">
                                    <DataPrivacyAndSecurity
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        handleChange = {this.handleChange}
                                        values={values}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="center-column col-12">
                            <StepCounter
                                count={2}
                            />
                        </div>
                    </div>
                </div>
            )
        case 2:
            return(
                <div>
                    <div className="row justify-content-center">
                        <div className="center-column col-12">
                            <div className="standard-shadow-card card my-4">
                                <div className="card-body onboard-card-padding">
                                    <PatientInformation
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        handleChange = {this.handleChange}
                                        values={values}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="center-column col-12">
                            <StepCounter
                                count={3}
                            />
                        </div>
                    </div>
                </div>
            )
        case 3:
            return(
                <div>
                    <div className="row justify-content-center">
                        <div className="center-column col-12">
                            <div className="standard-shadow-card card my-4">
                                <div className="card-body onboard-card-padding">
                                    <Goals
                                        prevStep={this.prevStep}
                                        handleChange = {this.handleChange}
                                        values={values}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="center-column col-12">
                            <StepCounter
                                count={4}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default MainForm;