// PatientInformation.jsx
import React, { Component } from 'react';
import Autocomplete from "./Autocomplete"


class PatientInformation extends Component{
    saveAndContinue = (e) => {
        e.preventDefault();
        this.setState({ patientGender: $('#patient-gender').val() });
        var dateborn = $('#date-born').val();
        console.log('moving on');
        console.log(dateborn);
        
        this.setState({ patientBirthday: dateborn });
        this.props.nextStep();
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    updateArrows() {
        $('#ui-datepicker-div').ready(function() {
            $('.ui-icon').each(function() {
                if($(this).html() == 'Prev'){
                    $(this).empty();
                    $(this).append('arrow_left');
                } else {
                    $(this).empty();
                    $(this).append('arrow_right');
                }
                $(this).removeClass();
                $(this).addClass('material-icons-round');
            });
        });
    }

    componentDidMount () {
        console.log(this);
        $('document').ready(function() {
            var empty = false;
            $('input').each(function() {
                if ($(this).val() == '') {
                    empty = true;
                }
            });
            if (empty) {
                $('.square-blue-button').attr('disabled', 'disabled');
            } else {
                $('.square-blue-button').removeAttr('disabled');
            }
        });
        $('input').keyup(function() {
            var empty = false;
            $('input').each(function() {
                if ($(this).val() == '') {
                    empty = true;
                }
            });
            if (empty) {
                $('.square-blue-button').attr('disabled', 'disabled');
            } else {
                $('.square-blue-button').removeAttr('disabled');
            }
        });
        $('.datepicker').on('input', function() {
            var empty = false;
            $('input').each(function() {
                if ($(this).val() == '') {
                    empty = true;
                }
            });
            if (empty) {
                $('.square-blue-button').attr('disabled', 'disabled');
            } else {
                $('.square-blue-button').removeAttr('disabled');
            }
        });

        $('.datepicker2').datepicker({
            dateFormat: "M d yy",
            duration: "fast",
            changeMonth: true,
            changeYear: true,
            yearRange: "1900:2020",
            beforeShow: updateArrows,
            onChangeMonthYear: updateArrows,
            onSelect: function() {
                var empty = false;
                $('input').each(function() {
                    if ($(this).val() == '') {
                        empty = true;
                    }
                });
                if (empty) {
                    $('.square-blue-button').attr('disabled', 'disabled');
                } else {
                    $('.square-blue-button').removeAttr('disabled');
                }
                $('#date-born').attr('value', $('#date-born').val());
                $('#date-born').focus();
                
            }
        }).after("<span style='margin-left: -36px; padding-top: 6px;' class='material-icons-round picker-button'>schedule</span>");

    }

    render(){
        const { values } = this.props
        var event = new Event('input', { bubbles: true });
        var response;

        if (values.user_type == 'patient'){
            response = <React.Fragment>
                <h2 id='sign-up-header'>Patient Information</h2>
                <p className='horizontal-divider-below light-gray-divider m-0'>A few questions to get us started.</p>
                
                <div className='spacer-32'/>
                
                <div className="standard-form-field mx-0">
                    <p className='m-0'><b>1. Gender</b></p>
                    <div className='spacer-8'/>
                    <input
                        className='mr-2 ml-16px'
                        id='gender'
                        type="text"
                        onChange={this.props.handleChange('patientGender')}
                        defaultValue={values.patientGender}
                    />
                </div>

                <div className='spacer-32'/>

                <div className="standard-form-field mx-0">
                    <p className='m-0'><b>2. Date born</b></p>
                    <div className='spacer-8'/>
                    <input
                        className='mr-2 ml-16px datepicker2'
                        id='date-born'
                        type="text"
                        placeholder='MMM DD YYYY'
                        onBlur={this.props.handleChange('patientBirthday')}
                        defaultValue={values.patientBirthday}
                    />
                    <i hidden className="far fa-calendar-alt pointer-on-hover" id='calendar-button'></i>
                </div>

                <div className='spacer-48'/>

                <div className='row'>
                    <div className='col-4 m-0 pr-2'>
                        <button className='back-button sign-up-white-btn p-0 m-0' onClick={this.back}>BACK</button>
                    </div>
                    <div className='col-8 m-0 pl-2'>
                        <button className="continue-button square-blue-button p-0 m-0" onClick={this.saveAndContinue}>CONTINUE</button>
                    </div>
                </div>
            </React.Fragment>
        }
        else {
            response = <React.Fragment>
                <h2 id='sign-up-header'>Patient Information</h2>
                <p className='horizontal-divider-below light-gray-divider m-0'>A few questions to get us started.</p>
                
                <div className='spacer-32'/>

                <p className='m-0'><b>1. What is the name of your child?</b></p>
                <div className='spacer-8 col-12'/>
                <div className='row mr-0 ml-16px'>
                    <div className='col-6 pr-2 p-0 standard-form-field'>
                        <label className="guardian-label">First name</label>
                        <input
                            type="text"
                            className='standard-form-field'
                            onChange={this.props.handleChange('patientFirstName')}
                            defaultValue={values.patientFirstName}
                        />
                    </div>
                    <div className='col-6 pl-2 p-0 standard-form-field'>
                        <label className="guardian-label">Last name</label>
                        <input
                            type="text"
                            className='standard-form-field'
                            onChange={this.props.handleChange('patientLastName')}
                            defaultValue={values.patientLastName}
                        />
                    </div>
                </div>

                <div className='spacer-48'/>
                
                <div className="standard-form-field mx-0">
                    <p className='m-0'><b>2. Gender</b></p>
                    <div className='spacer-8'/>
                    <input
                        className='mr-2 ml-16px'
                        id='gender'
                        type="text"
                        onChange={this.props.handleChange('patientGender')}
                        defaultValue={values.patientGender}
                    />
                </div>

                <div className='spacer-32'/>

                <div className="standard-form-field mx-0">
                    <p className='m-0'><b>3. Date born</b></p>
                    <div className='spacer-8'/>
                    <input
                        className='mr-2 ml-16px datepicker2'
                        id='date-born'
                        type="text"
                        placeholder='MMM DD YYYY'
                        onBlur={this.props.handleChange('patientBirthday')}
                        defaultValue={values.patientBirthday}
                    />
                    <i hidden className="far fa-calendar-alt pointer-on-hover" id='calendar-button'></i>
                </div>

                <div className='spacer-48'/>

                <div className='row'>
                    <div className='col-4 m-0 pr-2'>
                        <button className='back-button sign-up-white-btn p-0 m-0' onClick={this.back}>BACK</button>
                    </div>
                    <div className='col-8 m-0 pl-2'>
                        <button className="continue-button square-blue-button p-0 m-0" onClick={this.saveAndContinue}>CONTINUE</button>
                    </div>
                </div>
            </React.Fragment>
        }
        return(response);
    }
}

export default PatientInformation;