import React from "react"
//import $ from "jquery"
import PropTypes from "prop-types"
import MainForm from './MainForm';
import {Button} from 'react'

class SignUpRender extends React.Component {
  render () {
  	return (
      <MainForm
      	user_type={this.props.user_type}
      />
  	);
  }
}

SignUpRender.propTypes = {
  user_type: PropTypes.string
};
export default SignUpRender
