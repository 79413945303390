// StepCounter.jsx
import React, { Component } from 'react';

class StepCounter extends Component{
	render(){
		const count = this.props.count;
		const color1 = count > 0 ? { backgroundColor: '#0DCDBA' } : { backgroundColor: '#BDBDBD' };
		const color2 = count > 1 ? { backgroundColor: '#0DCDBA' } : { backgroundColor: '#BDBDBD' };
		const color3 = count > 2 ? { backgroundColor: '#0DCDBA' } : { backgroundColor: '#BDBDBD' };
		const color4 = count > 3 ? { backgroundColor: '#0DCDBA' } : { backgroundColor: '#BDBDBD' };
		return(
			<div>
				<p id='step-counter-text' className='text-align-center'>Step {count} of 4</p>
				<div className='row counter-row justify-content-center'>
					<div className='col counter-block' style={color1}>
					</div>
					<div className='col counter-block' style={color2}>
					</div>
					<div className='col counter-block' style={color3}>
					</div>
					<div className='col counter-block' style={color4}>
					</div>
				</div>
			</div>
		);
	}
}

export default StepCounter;